<template>
  <b-card-code>
    <!-- search input -->

    <b-form-group>
      <b-row>
       

        <b-col class="border-Primary" md="" xl="3">
          <b-button variant="purple"  @click="
                    $router.push({
                      name: 'addrole',
             
                    })
                  "
            ><span> إضافة دور آخر</span>
          </b-button>
        </b-col>
      </b-row>
    </b-form-group>

    <!-- table -->
      <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2 row">
        <b-col
          cols="6"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <label>Show</label>
          <v-select
            v-model="perPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block mx-50"
          />
          <label>entries</label>

        </b-col>

        <b-col
          cols="6"
          md="6"
          class=""
        >
          <label> {{ total }}      عدد الأدوار</label>
          <label />

        </b-col>

      </div>

      <b-table
      ref="refListTable"
        class="position-relative"
        :items="fetchData"
        responsive
        :fields="tableColumns"
        :current-page="currentPage"
        :per-page="perPage"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
      <template

          #cell(actions)="data"
        >
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
          <b-dropdown-item-button
                  @click="
                    $router.push({
                      name: 'showPermission',
                      params: { id: data.item.id },
                    })
                  "
                >
                  <feather-icon icon="EyeIcon" class="mr-50" />
                  <span>عرض</span>
                </b-dropdown-item-button>
                
                <b-dropdown-item-button @click="delet(data.item.id)">
                  <feather-icon icon="TrashIcon" class="mr-50" />
                  <span>حذف</span>
                </b-dropdown-item-button>
          </b-dropdown>
        </template>

         
            </b-table>

          

       
           </b-card>
           <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span
              class="text-muted"
            >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="total"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    
  </b-card-code>
</template>

<script>
import "vue-good-table/dist/vue-good-table.css";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BButton,
  BCol,
  BRow,
  BDropdown,
  BDropdownItem,
  BDropdownItemButton,

  BTable,
  BCardHeader, 
  BCardBody,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import vSelect from "vue-select";

import store from "@/store/index";
// import { codeBasic } from './code'
import useList from "./List";
export default {
  components: {
    BCardCode,
    BDropdownItemButton,
    BButton,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BCol,
    BRow,
    BDropdown,
    BDropdownItem,
    vSelect,
    BPagination,
  BTable,
  BCardHeader, 
  BCardBody,
  

  },
setup(){
  const {
    search,
      tableColumns,
      perPage,
      currentPage,
      total,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      fetchData,
      

      // Extra Filters
    } = useList()
    
    return {
      fetchData,
      search,
      filterOn: [],
      searchTerm: "",
      tableColumns,
      perPage,
      currentPage,
      total,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      refetchData,
    }

},

  data() {
    return {
      pageLength: 3,
      dir: false,
      roleName:'',

      columns: [
        {
          label: "الدور ",
          field: "name",
        },

        {
          label: "الخيارات",
          field: "id",
        },
      ],
      rows: [],
      token: "",
      searchTerm: "",
      id: "",
      status: [
        {
          1: "Current",
          2: "Professional",
          3: "Rejected",
          4: "Resigned",
          5: "Applied",
        },
        {
          1: "light-primary",
          2: "light-success",
          3: "light-danger",
          4: "light-warning",
          5: "light-info",
        },
      ],
    };
  },

  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current: "light-primary",
        Professional: "light-success",
        Rejected: "light-danger",
        Resigned: "light-warning",
        Applied: "light-info",
        /* eslint-enable key-spacing */
      };

      return (status) => statusColor[status];
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
  },

  created() {
    // this.token=localStorage.getItem("token")
    this.token = localStorage.getItem("token");
    // this.get();
  },

  methods: {
   
    delet(id) {
      
      this.$swal({
        title: "هل انت متأكد من الحذف",
        text: "",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "نعم",
        cancelButtonText: "لا",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$swal({
            icon: "warning",
            title: "ستقوم بحذف جميع البيانات الخاصة بالدور",
            confirmButtonText: "موافق",
            cancelButtonText: "الغاء",
            customClass: {
              confirmButton: "btn btn-primary",
              cancelButton: "لا",
            },
          }).then((result) => {
            if (result.value) {
              this.$http.delete("/api/role/" + id).then((res) => {
                //console.log(res);
                this.refetchData();                
              });
            }
          });
        }
      });
    },
    get() {
      
   
      this.$http.get("/api/v1/information_manager/role_management/roles").then((res) => {
        //console.log(res);
       
        //console.log("jijo", res.data.roles);

        this.rows = res.data.roles;
      });
    },

    // show(){
    //   //console.log("gu")
    //   this.$router.push({
    //     name: "show",
    //     params: { id: this.id },
    //   });
    // }
  }
};
</script>

<style scoped>
label {
  margin-left: 5px;
  color: #7e7a9f;
  font-weight: bold;
  font-family: "Cairo", sans-serif;
}
.btn-purple {
  color: white;
  background-color: #0971b8 !important;
  top: 40px;
}
</style>